import React, { Component } from 'react';
import { Card, CardBody } from '@paljs/ui/Card';
import Col from '@paljs/ui/Col';
import Row from '@paljs/ui/Row';
import { InputGroup } from '@paljs/ui/Input';
const isSSR = typeof window === 'undefined';
const CKEditor = !isSSR ? require('ckeditor4-react') : <div />;
//const ClassicEditor = !isSSR ? require('@ckeditor/ckeditor5-build-classic') : <div />;
import { Button } from '@paljs/ui/Button';
import styled from 'styled-components';
import Select from '@paljs/ui/Select';
import { Toastr } from '@paljs/ui/Toastr';
import firebase from 'gatsby-plugin-firebase';
import Popover2 from '@paljs/ui/Popover';
import ReportTab from './report-tabs';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Checkbox } from '@paljs/ui/Checkbox';
import { EvaIcon } from '@paljs/ui/Icon';
import { navigate } from 'gatsby';
import PopUp from './popUp';
import Loader from '../modal-overlays/loader';
import Tooltip from '@paljs/ui/Tooltip';
import axios from 'axios';

const Input = styled(InputGroup)`
  margin-bottom: 10px;
`;

const colButton = ['1/12', '2/12', '3/12', '4/12', '5/12', '6/12', '7/12', '8/12', '9/12', '10/12', '11/12', '12/12'];

const initial = {
  ov_title: 'Overview',
  ov_description: '',
  sections: [],
  tabsData: null,
};

class AddReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initial,
      all_products: [],
      products: [],
      selectedProduct: '',
      times: [],
      selectedTime: '',
      reportType: '',
      instruments: [],
      deleteIndex: { type: '', index: '' },
      showConfirm: false,
      reportDate: new Date(),
      published: 'no',
      reportStatus: 'In Progress',
      popUpType: '',
      modalIndexes: {},
      popUpData: '',
      sendEmail: false,
      docId: '',
      errors: {},
      isLoading: false,
      isImgLoader: false,
    };

    this.toastrRef = React.createRef();
    this.tabRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.id) {
      this.setData();
    } else {
      let doc = firebase.firestore().collection('reports').doc();
      this.setState({ docId: doc.id });
    }
    this.getProducts();
  }

  setData = () => {
    // set initial values for the report if id exists in url
    this.setState({ isLoading: true });
    let _self = this;
    firebase
      .firestore()
      .collection('reports')
      .doc(this.props.id)
      .get()
      .then((doc) => {
        let params = {
          ...doc.data(),
          reportDate: new Date(doc.data().reportDate.seconds * 1000),
          docId: this.props.id,
          reportType: doc.data().reportType,
          isLoading: false,
        };
        _self.setState(params, () => this.getTimes(doc.data().selectedProduct.value));
      });
  };

  getProducts = () => {
    // get products from firestore
    let _self = this;
    firebase
      .firestore()
      .collection('products')
      .get()
      .then((snapshot) => {
        let dropdown_values = [];
        let allProducts = [];

        snapshot.forEach((doc) => {
          dropdown_values.push({ value: doc.id, label: doc.data().name });
          allProducts.push({ id: doc.id, ...doc.data() });
        });
        _self.setState({ products: dropdown_values, all_products: allProducts });
      });
  };

  getTimes = (id, type = '') => {
    //get report times for a specific product
    let _self = this;
    firebase
      .firestore()
      .collection('products')
      .doc(id)
      .get()
      .then(function (doc) {
        if (doc.exists) {
          let insArr = [];

          for (let i = 0; i < doc.data().selectedIns.length; i++) {
            doc
              .data()
              .selectedIns[i].ref.get()
              .then((ins) => {
                insArr.push({ id: ins.id, ...ins.data() });
              });
          }

          let params = {
            times: doc.data().selectedReports,
            instruments: insArr,
          };

          if (type == 'onSelect') {
            params['reportType'] = '';
            params['selectedTime'] = '';
          }
          _self.setState(params);
        } else {
          _self.setState({ error: 'Invalid Id' });
        }
      })
      .catch((e) => {});
  };

  handleSelect = (values, field) => {
    this.setState({ [field]: values });
    if (field == 'selectedProduct' && this.state.selectedProduct.value !== values.value) {
      //get new report times if selected product is different from current product
      this.setState(initial, () => this.getTimes(values.value, 'onSelect'));
    } else if (field == 'reportType' && this.state.reportType.value !== values.value) {
      if (values.value == 'intraday') {
        this.setState(initial, () => this.getLastReport('intraday'));
      } else {
        this.setState(initial, () => this.autoFillReport(values.value));
      }
    } else if (field == 'selectedTime' && this.state.selectedTime.value !== values.value) {
      //get last report of currently selected product and report time
      this.setState(initial, () => this.getLastReport());
    } else if (field == 'published' && values == 'no') {
      this.setState({ sendEmail: false });
    }
  };

  autoFillReport = (repType) => {
    let _self = this;
    //this.setState({ isLoading: true })
    firebase
      .firestore()
      .collection('reports')
      .where('selectedProduct.value', '==', this.state.selectedProduct.value)
      .orderBy('updatedAt', 'desc')
      .get()
      .then((snapShot) => {
        if (snapShot.docs.length > 0) {
          let doc = snapShot.docs[0];
          let tempTabData = doc.data().tabsData;

          let currentIns = this.state.instruments.map((ins) => ins.id);

          //add data to tab data if it does not contain matching id
          for (let i = 0; i < currentIns.length; i++) {
            let tabIndex = tempTabData.findIndex((tab) => tab.id === currentIns[i]);
            if (tabIndex === -1) {
              tempTabData.push({
                id: this.state.instruments[i].id,
                label: this.state.instruments[i].name,
                sections: [],
                status: { label: 'Draft', value: 'draft' },
                title: '',
                subtitle: 'Trading Strategy',
                description: '',
                entry: '',
                stop: '',
                target: '',
              });
            } else {
              tempTabData[tabIndex]['status'] = { label: 'Draft', value: 'draft' };
            }
          }

          for (let j = 0; j < tempTabData.length; j++) {
            if (!currentIns.includes(tempTabData[j].id)) {
              tempTabData.splice(j, 1);
            }
          }

          _self.setState({
            sections: doc.data().sections,
            tabsData: tempTabData,
            ov_description: doc.data().ov_description,
            ov_title: doc.data().ov_title,
            selectedTime: doc.data().reportType,
            isLoading: false,
          });
        } else {
          _self.setState({ isLoading: false });
        }
      })
      .catch((e) => {
        _self.setState({ isLoading: false });
      });
  };

  getLastReport = (repType = '') => {
    // get last report of currently selected time and product
    let _self = this;
    this.setState({ isLoading: true });
    firebase
      .firestore()
      .collection('reports')
      .where('selectedProduct.value', '==', this.state.selectedProduct.value)
      .where('reportType.value', '==', repType == 'intraday' ? repType : this.state.selectedTime.value)
      .orderBy('updatedAt', 'desc')
      .get()
      .then((snapshot) => {
        if (snapshot.docs.length > 0) {
          let doc = snapshot.docs[0];
          let tempTabData = doc.data().tabsData;
          let currentIns = this.state.instruments.map((ins) => ins.id);

          //add data to tab data if it does not contain matching id
          for (let i = 0; i < currentIns.length; i++) {
            let tabIndex = tempTabData.findIndex((tab) => tab.id === currentIns[i]);
            if (tabIndex === -1) {
              tempTabData.push({
                id: this.state.instruments[i].id,
                label: this.state.instruments[i].name,
                sections: [],
                status: { label: 'Draft', value: 'draft' },
                title: '',
                subtitle: 'Trading Strategy',
                description: '',
                entry: '',
                stop: '',
                target: '',
              });
            } else {
              tempTabData[tabIndex]['status'] = { label: 'Draft', value: 'draft' };
            }
          }

          for (let j = 0; j < tempTabData.length; j++) {
            if (!currentIns.includes(tempTabData[j].id)) {
              tempTabData.splice(j, 1);
            }
          }

          let params = {
            sections: doc.data().sections,
            tabsData: tempTabData,
            ov_description: doc.data().ov_description,
            ov_title: doc.data().ov_title,
            isLoading: false,
          };

          if (repType == 'intraday') {
            params['selectedTime'] = { label: 'Intraday Report', value: 'intraday' };
          }

          _self.setState(params);
        } else {
          _self.setState({ isLoading: false });
          //console.log('no snap');
        }
      })
      .catch((e) => {
        //console.log(e);
        _self.setState({ isLoading: false });
      });
  };

  handleAddSection = (index) => {
    //add a new section to the report
    let tempArr = [...this.state.sections];
    let secObj = { title: '', rows: [] };
    tempArr.splice(index, 0, secObj);
    this.setState({ sections: tempArr });
  };

  handleSectionTitle = (e, index) => {
    // change title of specific section
    let tempArr = [...this.state.sections];
    let tempObj = tempArr[index];
    tempObj[e.target.name] = e.target.value;
    this.setState({ sections: tempArr });
  };

  chooseColumnType = (sectionIndex, rowIndex, colIndex, type) => {
    let tempArr = [...this.state.sections];
    let tempObj = tempArr[sectionIndex]['rows'][rowIndex];

    if (type == 'image') {
      tempObj.columns[colIndex]['colType'] = 'image';
    } else {
      tempObj.columns[colIndex]['colType'] = 'editor';
    }

    //save the indexes so that values can be initialised later
    let modalIndexes = { sectionIndex, rowIndex, colIndex };

    this.setState({ sections: tempArr, popUpType: type, modalIndexes, popUpData: '' });
  };

  handleDelete = (type, sectionIndex = '', rowIndex = '', columnIndex = '', event = null) => {
    //save the indexes of the object to be deleted and show confirm modal
    event && event.stopPropagation();
    this.setState({ showConfirm: true, deleteIndex: { type, sectionIndex, rowIndex, columnIndex } });
  };

  confirmDelete = () => {
    // if user presss confirm on in the confirm modal
    let tempArr = [...this.state.sections];
    const { deleteIndex } = this.state;

    //switch between the section, row, column delete and update state
    if (deleteIndex.type == 'section') {
      tempArr.splice(deleteIndex.index, 1);
    } else if (deleteIndex.type == 'row') {
      tempArr[deleteIndex.sectionIndex]['rows'].splice(deleteIndex.rowIndex, 1);
    } else if (deleteIndex.type == 'column') {
      tempArr[deleteIndex.sectionIndex]['rows'][deleteIndex.rowIndex]['columns'].splice(deleteIndex.columnIndex, 1);
    } else if (deleteIndex.type == 'switch') {
      tempArr[deleteIndex.sectionIndex]['rows'][deleteIndex.rowIndex]['columns'][deleteIndex.columnIndex]['colType'] =
        'blank';
      tempArr[deleteIndex.sectionIndex]['rows'][deleteIndex.rowIndex]['columns'][deleteIndex.columnIndex]['colValue'] =
        '';
    }
    this.setState({ sections: tempArr, showConfirm: false });
  };

  handleEditor = (data) => {
    //save editor data in the specific column
    let tempArr = [...this.state.sections];
    let sectionPos = this.state.modalIndexes['sectionIndex'];
    let rowPos = this.state.modalIndexes['rowIndex'];
    let colPos = this.state.modalIndexes['colIndex'];

    tempArr[sectionPos]['rows'][rowPos].columns[colPos]['colValue'] = data;
    this.setState({ sections: tempArr, popUpType: '' });
  };

  openPopUpData = (sectionIndex, rowIndex, colIndex, type) => {
    // get the current selected column value
    let popUpData = this.state.sections[sectionIndex]['rows'][rowIndex]['columns'][colIndex]['colValue'];
    let modalIndexes = { sectionIndex, rowIndex, colIndex };

    this.setState({ popUpData, popUpType: type, modalIndexes });
  };

  handleOverview = (field, data) => {
    //set the overview title and description
    this.setState({ [field]: data });
  };

  validateFields = () => {
    let formSubmit = true;
    let tempErrors = {};

    if (!this.state.selectedProduct) {
      tempErrors['product'] = 'This field is required';
      formSubmit = false;
    } else if (!this.state.reportType) {
      tempErrors['reportType'] = 'This field is required';
      formSubmit = false;
    } else if (!this.state.reportStatus) {
      tempErrors['reportStatus'] = 'This field is required';
      formSubmit = false;
    }

    this.setState({ errors: tempErrors });
    return formSubmit;
  };

  saveDetails = () => {
    if (this.validateFields()) {
      let tempProduct = this.state.all_products.find((item) => item.id == this.state.selectedProduct.value);

      let tempTabData = this.tabRef.current.state.tabData;

      let complTabsIds = tempTabData.filter((item) => item.status.value === 'completed').map((obj) => obj.id);

      let insEmailValues = [];
      let marketsArr = [];

      for (let i = 0; i < this.state.instruments.length; i++) {
        if (complTabsIds.includes(this.state.instruments[i].id)) {
          insEmailValues.push({
            imgUrl: this.state.instruments[i].imageUrl,
            name: this.state.instruments[i].name,
          });
          marketsArr.push(this.state.instruments[i].market);
        }
      }
      tempProduct['selectedIns'] = insEmailValues;

      if (this.state.sendEmail == true) {
        let emailParams = {
          product: tempProduct,
          report_status: this.state.reportStatus,
          report_type: this.state.reportType,
          title: this.state.ov_title,
          description: this.state.ov_description,
          doc_id: this.props.id ? this.props.id : this.state.docId,
          updatedAt: Date.now(),
          markets: marketsArr,
          report_date: this.state.reportDate,
        };

        this.sendEmails(emailParams);
      }
      this.setState({ isLoading: true });
      let _self = this;
      let params = {
        selectedProduct: this.state.selectedProduct,
        reportType: this.state.reportType,
        ov_title: this.state.ov_title,
        ov_description: this.state.ov_description,
        sections: this.state.sections,
        reportDate: this.state.reportDate,
        reportStatus: this.state.reportStatus,
        published: this.state.published,
        tabsData: tempTabData,
      };

      //set the product disclaimer if exists
      if (tempProduct.product_disclaimer && tempProduct.product_disclaimer !== '') {
        params['product_disclaimer'] = tempProduct.product_disclaimer;
      }

      // set the created date for new reports and update date for exisitng reports
      if (!this.props.id) {
        params['createdAt'] = firebase.firestore.Timestamp.now();
        params['updatedAt'] = firebase.firestore.Timestamp.now();
      } else {
        params['updatedAt'] = firebase.firestore.Timestamp.now();
      }

      let collectionRef = firebase.firestore().collection('reports');
      if (this.props.id) {
        //update the report
        collectionRef
          .doc(this.props.id)
          .update(params)
          .then(() => {
            _self.setState({ isLoading: false });
            navigate('/admin/reports/report-listing');
          })
          .catch((err) => {
            _self.setState({ isLoading: false });
            _self.toastrRef.current?.add('Error Updating Details', 'Error', { status: 'Danger' });
          });
      } else {
        //add a new report
        collectionRef
          .doc(this.state.docId)
          .set(params)
          .then(() => {
            _self.setState({ isLoading: false });
            navigate('/admin/reports/report-listing');
          })
          .catch((err) => {
            _self.setState({ isLoading: false });
            _self.toastrRef.current?.add('Error Adding Details', 'Error', { status: 'Danger' });
          });
      }
    }
  };

  sendEmails = (params) => {
    axios
      .post(`${process.env.API_ENDPOINT}/send-emails`, params, { headers: { 'Content-Type': 'application/json' } })
      .then((res) => {
        //console.log('sent email');
      })
      .catch((e) => {
        //console.log(e);
      });
  };

  uploadImage = (file) => {
    //upload file to firebase
    let _self = this;
    this.setState({ isImgLoader: true });
    let { modalIndexes } = this.state;

    //store the image in firebase under a specific report id
    let storageRef = firebase
      .storage()
      .ref('reports/')
      .child(this.state.docId)
      .child(
        `${this.state.docId}-${modalIndexes['sectionIndex']}-${modalIndexes['rowIndex']}-${modalIndexes['colIndex']}`,
      );
    let tempArr = [...this.state.sections];
    let tempObj = tempArr[modalIndexes.sectionIndex]['rows'][modalIndexes.rowIndex]['columns'][modalIndexes.colIndex];

    storageRef
      .put(file)
      .then((result) => {
        storageRef.getDownloadURL().then((result) => {
          tempObj['colValue'] = result;
          _self.setState({ sections: tempArr, popUpType: '', isImgLoader: false });
        });
      })
      .catch((e) => {
        _self.setState({ isImgLoader: false });
      });
  };

  addRowInSection = (sectionIndex, rowIndex) => {
    let tempArr = [...this.state.sections];
    let colObj = { columns: [] };
    tempArr[sectionIndex]['rows'].splice(rowIndex, 0, colObj);
    this.setState({ sections: tempArr });
  };

  addColumnInRow = (sectionIndex, rowIndex, colSize) => {
    //add a column of the chosen size to a row
    let tempArr = [...this.state.sections];
    let rowArr = tempArr[sectionIndex]['rows'][rowIndex];
    rowArr['columns'].push({ colSize, colType: 'blank', colValue: '' });
    this.setState({ sections: tempArr });
  };

  render() {
    return (
      <>
        <Row>
          <Toastr ref={this.toastrRef} />
          <Col breakPoint={{ xs: 12, md: 12 }}>
            <Card>
              <header>Add Report</header>
              <div className="row mx-0 mt-4">
                <div className="col-9">
                  <CardBody className="p-0 no-scroll">
                    <div className="report-box">
                      <Input fullWidth shape="SemiRound">
                        <input
                          type="text"
                          placeholder="Enter Overview"
                          name="ov_title"
                          onChange={(e) => this.handleOverview('ov_title', e.target.value)}
                          value={this.state.ov_title}
                        />
                      </Input>

                      <br />
                      {!isSSR && (
                        <CKEditor
                          data={this.state.ov_description ? this.state.ov_description : ''}
                          type="classic"
                          config={{ allowedContent: true }}
                          onChange={(event) => {
                            this.handleOverview('ov_description', event.editor.getData());
                          }}
                        />
                      )}
                    </div>
                    <Row>
                      {' '}
                      <Col className="text-center">
                        <Button onClick={() => this.handleAddSection(0)}>Add Section to start</Button>
                      </Col>
                    </Row>
                    {this.state.sections.length
                      ? this.state.sections.map((section, i) => (
                          <div key={i}>
                            <div className="remove-btn">
                              <span onClick={() => this.handleDelete('section', i)}>
                                <EvaIcon name="close-circle-outline" />
                                Remove Section
                              </span>
                            </div>
                            <div className="report-box box-pane">
                              <Input fullWidth shape="SemiRound">
                                <input
                                  type="text"
                                  placeholder="Enter Title"
                                  value={section.title}
                                  onChange={(e) => this.handleSectionTitle(e, i)}
                                  name="title"
                                />
                              </Input>
                              <div className="text-center">
                                <Button onClick={() => this.addRowInSection(i, 0)} status="Info">
                                  Add Row To Start
                                </Button>
                              </div>
                              {section.rows.map((row, rowIndex) => (
                                <div key={rowIndex}>
                                  <Row key={rowIndex} className="section-divider">
                                    <div className="blank-wrap">
                                      <Popover2
                                        className="inline-block"
                                        trigger="hover"
                                        placement="top"
                                        overlay={
                                          <Card className="col-popover popover-card">
                                            <CardBody>
                                              {colButton.map((colSizeButton, colSizeButtonKey) => (
                                                <>
                                                  <button
                                                    onClick={() =>
                                                      this.addColumnInRow(i, rowIndex, colSizeButtonKey + 1)
                                                    }
                                                    key={colSizeButtonKey}
                                                  >
                                                    {colSizeButton}
                                                  </button>
                                                  <br />
                                                </>
                                              ))}
                                            </CardBody>
                                          </Card>
                                        }
                                      >
                                        <button className="basic-btn">
                                          <EvaIcon name="plus-circle-outline" /> Column
                                        </button>
                                      </Popover2>
                                      <div className="action-btn-wrap">
                                        <span
                                          className="basic-remove-btn"
                                          onClick={() => this.handleDelete('row', i, rowIndex)}
                                        >
                                          <EvaIcon name="trash-outline" />
                                        </span>
                                      </div>
                                    </div>

                                    {row.columns.map((column, colIndex) => (
                                      <Col breakPoint={{ xs: column.colSize }} key={colIndex}>
                                        <>
                                          {column.colType == 'blank' && (
                                            <div className="grey-blank no-option">
                                              <div className="remove-col-wrap">
                                                <Tooltip trigger="hover" placement="right" content="delete">
                                                  <span
                                                    className="trash-wrap"
                                                    onClick={(e) =>
                                                      this.handleDelete('column', i, rowIndex, colIndex, e)
                                                    }
                                                  >
                                                    <EvaIcon name="trash-outline" />
                                                  </span>
                                                </Tooltip>
                                              </div>
                                              <span className="col-size-show">
                                                <em>{column.colSize}</em>
                                              </span>
                                              <div className="column-type text-right">
                                                <Popover2
                                                  className="inline-block"
                                                  trigger="click"
                                                  placement="top"
                                                  overlay={
                                                    <Card className="popover-card">
                                                      <CardBody>
                                                        <Button
                                                          className="m-1"
                                                          onClick={() =>
                                                            this.chooseColumnType(i, rowIndex, colIndex, 'image')
                                                          }
                                                        >
                                                          <EvaIcon name="image-outline" />
                                                        </Button>
                                                        <Button
                                                          className="m-1"
                                                          onClick={() =>
                                                            this.chooseColumnType(i, rowIndex, colIndex, 'editor')
                                                          }
                                                        >
                                                          <EvaIcon name="text-outline" />
                                                        </Button>
                                                      </CardBody>
                                                    </Card>
                                                  }
                                                >
                                                  <Button>
                                                    <EvaIcon name="plus-circle-outline" />
                                                  </Button>
                                                </Popover2>
                                              </div>
                                            </div>
                                          )}
                                          {column.colType == 'image' && (
                                            <>
                                              <div
                                                className="grey-blank no-option"
                                                onClick={() => this.openPopUpData(i, rowIndex, colIndex, 'image')}
                                              >
                                                <div className="remove-col-wrap">
                                                  <Tooltip trigger="hover" placement="right" content="delete">
                                                    <span
                                                      className="trash-wrap"
                                                      onClick={(e) =>
                                                        this.handleDelete('column', i, rowIndex, colIndex, e)
                                                      }
                                                    >
                                                      <EvaIcon name="trash-outline" />
                                                    </span>
                                                  </Tooltip>
                                                  <Tooltip trigger="hover" placement="right" content="clear">
                                                    <span
                                                      className={column.colType == 'blank' ? 'd-none' : ''}
                                                      onClick={(e) =>
                                                        this.handleDelete('switch', i, rowIndex, colIndex, e)
                                                      }
                                                    >
                                                      <EvaIcon name="refresh-outline" />
                                                    </span>
                                                  </Tooltip>
                                                </div>
                                                {column.colValue ? (
                                                  <img src={column.colValue} width="150px" />
                                                ) : (
                                                  <span>
                                                    <EvaIcon name="image-outline" />
                                                  </span>
                                                )}
                                                <span className="col-size-show">
                                                  <em>{column.colSize}</em>
                                                </span>
                                              </div>
                                            </>
                                          )}
                                          {column.colType == 'editor' && (
                                            <>
                                              <div
                                                className={`grey-blank ${!column.colValue.length ? 'no-option' : ''}`}
                                                onClick={() => this.openPopUpData(i, rowIndex, colIndex, 'editor')}
                                              >
                                                <div className="remove-col-wrap">
                                                  <Tooltip trigger="hover" placement="right" content="delete">
                                                    <span
                                                      className="trash-wrap"
                                                      onClick={(e) =>
                                                        this.handleDelete('column', i, rowIndex, colIndex, e)
                                                      }
                                                    >
                                                      <EvaIcon name="trash-outline" />
                                                    </span>
                                                  </Tooltip>
                                                  <Tooltip trigger="hover" placement="right" content="clear">
                                                    <span
                                                      className={column.colType == 'blank' ? 'd-none' : ''}
                                                      onClick={(e) =>
                                                        this.handleDelete('switch', i, rowIndex, colIndex, e)
                                                      }
                                                    >
                                                      <EvaIcon name="refresh-outline" />
                                                    </span>
                                                  </Tooltip>
                                                </div>
                                                <span className="col-size-show">
                                                  <em>{column.colSize}</em>
                                                </span>
                                                {column.colValue.length ? (
                                                  <div
                                                    className="mt-3"
                                                    dangerouslySetInnerHTML={{ __html: column.colValue }}
                                                  />
                                                ) : (
                                                  <Button>Editor</Button>
                                                )}
                                              </div>
                                            </>
                                          )}
                                        </>
                                      </Col>
                                    ))}
                                  </Row>
                                  <div className="text-center">
                                    <Button
                                      status="Info"
                                      className="mt-2"
                                      onClick={() => this.addRowInSection(i, rowIndex + 1)}
                                    >
                                      Add Next Row
                                    </Button>
                                  </div>
                                </div>
                              ))}
                              <Row></Row>
                            </div>
                            <div className="text-center">
                              <Button onClick={() => this.handleAddSection(i + 1)}>Add next Section</Button>
                            </div>
                          </div>
                        ))
                      : null}
                    <br />

                    {this.state.reportType !== '' && (
                      <ReportTab
                        tabs={this.state.instruments}
                        ref={this.tabRef}
                        tabData={this.state.tabsData}
                        selectedProd={this.state.selectedProduct}
                        docId={this.state.docId}
                      />
                    )}
                  </CardBody>
                </div>
                <div className="col-3 pl-0 sticky-box">
                  <div className="form-group">
                    <div className="small-label">Product</div>
                    <Select
                      fullwidth
                      placeholder="Select Product"
                      options={this.state.products}
                      onChange={(values) => this.handleSelect(values, 'selectedProduct')}
                      value={this.state.selectedProduct}
                      isDisabled={this.props.id ? true : false}
                    />
                    <p className="form-error">{this.state.errors.product}</p>
                  </div>
                  <div className="form-group">
                    <div className="small-label">Time</div>
                    <Select
                      fullwidth
                      placeholder="Select Report"
                      options={this.state.times}
                      onChange={(values) => this.handleSelect(values, 'reportType')}
                      value={this.state.reportType}
                      isDisabled={this.props.id ? true : false}
                    />
                    <p className="form-error">{this.state.errors.reportType}</p>
                  </div>
                  {!this.props.id && (
                    <div className="form-group">
                      <div className="small-label">Pull from previous Report</div>
                      <Select
                        fullwidth
                        placeholder="Select Type"
                        options={this.state.times}
                        onChange={(values) => this.handleSelect(values, 'selectedTime')}
                        value={this.state.selectedTime}
                      />
                    </div>
                  )}
                  <div className="form-group">
                    <div className="small-label">Report Date</div>
                    <DatePicker
                      className="form-control w-100"
                      selected={this.state.reportDate}
                      onChange={(date) => this.setState({ reportDate: date })}
                    />
                  </div>
                  <div className="form-group">
                    <div className="small-label">Status</div>
                    <div className="radio-wrap">
                      <div className="radio d-block">
                        <label>
                          <input
                            name="reportStatus"
                            type="radio"
                            value="Not Started"
                            checked={this.state.reportStatus === 'Not Started'}
                            onChange={(e) => this.handleSelect(e.target.value, 'reportStatus')}
                          />
                          Not Started
                        </label>
                      </div>
                      <div className="radio d-block">
                        <label>
                          <input
                            name="reportStatus"
                            type="radio"
                            value="In Progress"
                            checked={this.state.reportStatus === 'In Progress'}
                            onChange={(e) => this.handleSelect(e.target.value, 'reportStatus')}
                          />
                          In Progress
                        </label>
                      </div>
                      <div className="radio d-block">
                        <label>
                          <input
                            name="reportStatus"
                            type="radio"
                            value="Completed"
                            checked={this.state.reportStatus === 'Completed'}
                            onChange={(e) => this.handleSelect(e.target.value, 'reportStatus')}
                          />
                          Completed
                        </label>
                      </div>
                    </div>
                    <p className="form-error">{this.state.errors.reportStatus}</p>
                  </div>
                  <div className="form-group">
                    <div className="small-label">Published</div>
                    <div className="radio-wrap">
                      <div className="radio d-block">
                        <label>
                          <input
                            name="published"
                            type="radio"
                            value="yes"
                            checked={this.state.published === 'yes'}
                            onChange={(e) => this.handleSelect(e.target.value, 'published')}
                          />
                          Yes
                        </label>
                      </div>
                      <div className="radio d-block">
                        <label>
                          <input
                            name="published"
                            type="radio"
                            value="no"
                            checked={this.state.published === 'no'}
                            onChange={(e) => this.handleSelect(e.target.value, 'published')}
                          />
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                  <div>
                    {this.state.published == 'yes' && (
                      <Checkbox
                        checked={this.state.sendEmail}
                        onChange={() => this.handleSelect(!this.state.sendEmail, 'sendEmail')}
                      >
                        Send Email
                      </Checkbox>
                    )}
                  </div>
                  <div className="btn-wrapper">
                    <Button onClick={this.saveDetails}>Save</Button>
                  </div>
                </div>
              </div>
            </Card>
          </Col>
        </Row>
        {this.state.showConfirm && (
          <SweetAlert
            warning
            showCancel
            cancelBtnBsStyle="info"
            confirmBtnText="Yes"
            confirmBtnBsStyle="danger"
            title="Are you sure?"
            onConfirm={this.confirmDelete}
            onCancel={() => this.setState({ showConfirm: false })}
          />
        )}
        <PopUp
          isOpen={this.state.popUpType !== ''}
          close={() => this.setState({ popUpType: '' })}
          type={this.state.popUpType}
          saveEditor={this.handleEditor}
          data={this.state.popUpData}
          saveImg={this.uploadImage}
          isImgLoader={this.state.isImgLoader}
        />
        <Loader isOpen={this.state.isLoading} />
      </>
    );
  }
}

export default AddReport;
